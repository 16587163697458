import React from 'react';
import styled from '@emotion/styled';
import { HomeText } from './layout/Home-Text';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';

const StyledFormPanels = styled.div`
    padding: 54px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2,
    p {
        text-align: center;
    }
    h2 + p {
        width: 84%;
        margin: 20px auto;
        text-align: center;
    }
    p + div {
        width: 75%;
        margin: 25px auto;
    }
    .body-text {
        margin: 25px auto;
        width: 85%;
    }
    @media ${device.tablet} {
        padding: 130px 0px 0px;
    }
    @media ${device.laptopS} {
        p + div {
            width: 45%;
        }
        .body-text {
            min-width: 600px;
            margin-bottom: 40px;
        }
    }
    @media ${device.laptopL} {
        padding: 28px 0px;
    }
    @media ${device.desktop} {
        padding: 68px 0px 108px;
    }
`;

const Panels = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    a {
        transition: 0.1s color ease-in;
        color: ${colors.blueMedium};
    }
    a:hover {
        color: ${colors.blueLight};
    }
    @media ${device.tablet} {
        display: flex;
        align-items: center;
        max-width: 700px;
        justify-content: space-around;
        flex-direction: row;
    }
    @media ${device.laptopS} {
        display: flex;
        align-items: center;
        width: 1360px;
        gap: 40px;
        justify-content: center;
        flex-direction: row;
    }
`;

const Panel = styled.div`
    border: 1px solid #c0cbcf;
    transition: 0.2s border ease-in;
    border-radius: 15px;
    width: 305px;
    height: 382px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0em;
    margin: 0px 0px 32px;
    div {
        padding: 0em;
        position: relative;
        bottom: 19px;
        transition: all 0.2s ease-in-out;
        border-radius: 0px 0px 15px 15px;
        min-height: 265px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
        min-width: 305px;
        img {
            border-radius: 0px 0px 15px 15px;
            padding: 0em;
            transform: scale(1.05);
            transition: 0.1s ease-in;
        }
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 24px, rgba(0, 0, 0, 0.12) 0px 7px 7px;
        }
    }
    &:hover {
        border: 1px solid ${colors.blueLight};
        img {
            transform: scale(1.08);
            box-shadow: none;
            transition: all 0.1s ease-in-out;
        }
    }

    p {
        padding: 0 30px;
        font-family: 'Lato';
        font-weight: 600;
        font-size: 24px;
        line-height: 132.5%;
    }

    @media ${device.laptopS} {
        margin-bottom: 0;
    }
`;

const text = {
    title: 'Are You A First Time Patient?',
    subTitle: `Because Everyone deserves to Smile`,
    bodyText: `Please fill out all three forms to help your appointment move quicker and smoother.`,
};

export const FormPanels: React.FC = () => {
    return (
        <StyledFormPanels>
            <HomeText>
                {' '}
                <h2 className="text">{text.title}</h2>
                <p>{text.subTitle}</p>
                <div></div>
                <p className="body-text">{text.bodyText}</p>
            </HomeText>
            <Panels>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://patientviewer.com/WebFormsGWT/GWT/WebForms/WebForms.html?DOID=75350&WSDID=69628"
                >
                    <Panel>
                        <p>1. New Patient Health History Form</p>
                        <StaticImage
                            src="../images/girl-smiling-form.jpeg"
                            alt="girl smiling with patient form"
                            placeholder="none"
                            quality={100}
                        />
                    </Panel>
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://patientviewer.com/WebFormsGWT/GWT/WebForms/WebForms.html?DOID=75350&WSDID=72854"
                >
                    <Panel>
                        <p>2. HIPAA Patient Consent Form</p>
                        <StaticImage
                            src="../images/hands-hipaa.jpeg"
                            alt="man holding woman's hand"
                            placeholder="none"
                            quality={100}
                        />
                    </Panel>
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://patientviewer.com/WebFormsGWT/GWT/WebForms/WebForms.html?DOID=75350&WSDID=72855"
                >
                    <Panel>
                        <p>3. Financial Agreement Form</p>
                        <StaticImage
                            src="../images/piggy-bank.jpeg"
                            alt="person inserting coin into piggy bank"
                            placeholder="none"
                            quality={100}
                        />
                    </Panel>
                </a>

                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://patientviewer.com/WebFormsGWT/GWT/WebForms/WebForms.html?DOID=75350&RKID=24094&WSDID=113237"
                >
                    <Panel>
                        <p>4. Dental Insurance Form</p>
                        <StaticImage
                            src="../images/insurance-form.jpg"
                            alt="form"
                            placeholder="none"
                            quality={100}
                        />
                    </Panel>
                </a>
            </Panels>
        </StyledFormPanels>
    );
};
